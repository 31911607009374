import Head from 'next/head'
import { filter, head, map, prop } from 'ramda'
import React from 'react'
import FormattedDate from '../../components/CMS/FormattedDate'

import PageContent from '../../components/CMS/PageContent'
import UserCard from '../../ui/cards/rows/user'
import ClockIcon from '../../ui/icons/Clock'
import CommentIcon from '../../ui/icons/Comment'

import TagsIcon from '../../ui/icons/Tags'

import { TextLink } from '../../ui/link'
import SocialShare from '../../ui/social-share'
import { getImageURL, link_for_resource } from '../../url'

import { PostResponseFragment, PublicPostEntryFragment } from '../operations'

type Item = PublicPostEntryFragment
type FullItem = PostResponseFragment

export const Page = ({ model, url }: { model: FullItem; url: string }) => {
  const categories = map(
    prop('category'),
    filter(x => x.category.domain === 'post_category', model.categories)
  )
  const tags = map(
    prop('category'),
    filter(x => x.category.domain === 'post_tag', model.categories)
  )

  const featuredImgUrl =
    model.image && model.show_feature_image && model.image.file
      ? getImageURL('medium', 'cms', model.image.path)
      : null

  return (
    <article
      className="post"
      id={'post-' + model.id}
      style={{ display: 'flex', flexDirection: 'column' }}
    >
      <Head>
        <link rel="stylesheet" href="/static/carousel.min.css" />
      </Head>

      <header className="post__header">
        <div className="post__category">
          {categories.length ? (
            <>
              {map(
                category => (
                  <TextLink
                    key={category.slug}
                    to={link_for_resource('post-category', category.slug)}
                  >
                    {category.name}
                  </TextLink>
                ),
                categories
              )}
            </>
          ) : null}
        </div>
        <h1 className="post__title">{model.title}</h1>

        <div className="post__meta">
          {model.publisher ? (
            <div className="post__meta-name">
              <a href={`/members/${model.publisher.id}`}>
                {model.publisher.name}
              </a>
            </div>
          ) : null}
          <div className="post__meta-date">
            <ClockIcon width={15} />
            <FormattedDate date={model.date} timeZone="Europe/London" />
          </div>
          {model.comments.length ? (
            <div className="post__meta-comments">
              <span className="post__meta-comments-icon">
                <CommentIcon width={15} />
              </span>
              <a href="#comments">{model.comments.length} comments</a>
            </div>
          ) : null}
        </div>
        <div className="post__tags-social">
          {tags.length ? (
            <div className="post__tags">
              <div className="post__tags-label">
                <TagsIcon color="#002D5D" />
              </div>
              {map(
                category => (
                  <TextLink
                    key={category.slug}
                    to={link_for_resource('post-tag', category.slug)}
                  >
                    <span className="post__tag">{category.name}</span>
                  </TextLink>
                ),
                tags
              )}
            </div>
          ) : null}
          {/*
            <TextLink to={ link_for_resource('post', model.slug) } rel="bookmark">
              Bookmark?
            </TextLink>
          */}

          <SocialShare
            url={url}
            title={model.title}
            feature="share"
            theme="dark"
            isMedShrOpen
          />
        </div>
        {featuredImgUrl && (
          <img
            alt={'Featured image'}
            src={featuredImgUrl}
            style={{ marginBottom: 20, width: '100%' }}
          />
        )}
      </header>

      <div className="post__content">
        <PageContent blocks={model.body.blocks || []} />
      </div>

      {model.publisher ? (
        <UserCard
          className="posted-by"
          card={{
            target: model.publisher.id,
          }}
        />
      ) : null}
      <div className="post__content">
        <SocialShare
          url={url}
          title={model.title}
          feature="share"
          theme="dark"
          isMedShrOpen
        />
      </div>
    </article>
  )
}

export const Entry = ({ model }: { model: Item }) => {
  const image =
    model.image && model.image.file
      ? getImageURL('medium', 'cms', model.image.path)
      : head(
          filter(
            x => !!x,
            map(block => {
              if (block.type === 'image') {
                return getImageURL('medium', 'cms', block.value.path)
              }
              if (block.type === 'html') {
                const m = block.value.match(/<img [^>]*src=\\?"([^"]+)"/)
                if (m && !/GU-profile-pic/.test(m[1])) {
                  return m[1]
                }
              }
            }, model.body.blocks || [])
          )
        )

  const image_url = image || '/static/images/medshr-og.png'
  const category = prop(
    'category',
    head(filter(x => x.category.domain === 'post_category', model.categories))
  )

  const linkToPost = link_for_resource('post', model.slug)
  const linkToProfile = model.publisher
    ? `/members/${model.publisher.id}`
    : null

  return (
    <div className="post-entry" id={'post-' + model.id}>
      <div className="post-entry__image-wrapper">
        <div
          className="post-entry__image-background"
          style={{ backgroundImage: `url(${image_url})` }}
        />
        <TextLink to={linkToPost}>
          {
            <img
              src={image_url}
              className="post-entry__image"
              alt={model.title}
            />
          }
        </TextLink>
      </div>

      <div className="post-entry__content-wrapper">
        {category ? (
          <TextLink to={link_for_resource('post-category', category.slug)}>
            <div className="post-entry__category">{category.name}</div>
          </TextLink>
        ) : null}

        <TextLink to={linkToPost}>
          <h2>{model.title}</h2>
        </TextLink>

        <div className="post-entry__publisher-and-date">
          {model.publisher ? (
            <TextLink to={linkToProfile}>
              <div style={{ color: '#4440FF' }}>
                <b>{model.publisher.name}</b>
              </div>
            </TextLink>
          ) : null}
          &nbsp;&nbsp;&nbsp;
          <div>
            <FormattedDate
              className="post-entry__date-published"
              date={model.date}
              timeZone="Europe/London"
            />
          </div>
        </div>

        {model.excerpt ? (
          <div className="post-entry__excerpt">{model.excerpt}</div>
        ) : null}
      </div>
    </div>
  )
}
