import { path } from 'ramda'
import { useState } from 'react'
import { useQuery, useMutation } from '@apollo/client'

import {
  usePublicPostsQuery,
  PublicPostsQuery,
  usePublicPostsWithCategoryQuery,
  PublicPostsWithCategoryQuery,
  PublicPostEntryFragment,
  PublicPostCategoryFragment,
  usePostsBySlugQuery,
  PostResponseFragment,
  useAddPostCommentMutation,
  AddPostCommentMutation,
  usePublicPostsLazyQuery,
} from '../operations'

export type List = PublicPostsQuery
export type Item = PublicPostEntryFragment
export type ListWithCategory = PublicPostsWithCategoryQuery
export type PostCategory = PublicPostCategoryFragment
export type FullItem = PostResponseFragment

export const usePublicPostsFeed = usePublicPostsQuery
export const usePublicPostsLazyFeed = usePublicPostsLazyQuery
export const usePublicPostsFeedWithCategory = usePublicPostsWithCategoryQuery

export const usePostBySlug = (
  slug: string
): {
  post?: FullItem
  loading: boolean
  error?: Error
  refetch: () => void
} => {
  const { data, ...rest } = usePostsBySlugQuery({ variables: { slug } })
  if (data) {
    return { post: data.results[0], ...rest }
  }
  return rest
}

export const useCommentPost = (post: FullItem, refresh: Function) => {
  const [postingComment, setPostingComment] = useState({})
  const [addComment] = useAddPostCommentMutation({
    update: (cache, { data }) => {
      const model = path(['insert_post_comments', 'returning', 0, 'post'], data)
      refresh({ model: model })
    },
    onCompleted: data => {
      setPostingComment({ ...postingComment, [post.id]: undefined })
    },
    onError: error => {
      console.log('addComment ', error)
      if (/JWTExpired/.test(error.toString())) {
        // refresh jwt, resubmit
      }
    },
  })
  return (user, comment) => {
    setPostingComment({ ...postingComment, [post.id]: comment })
    return addComment({
      variables: {
        user_values: {
          id: user.id,
          name: user.name,
        },
        values: {
          post_id: post.id,
          body: comment,
        },
      },
    })
  }
}
