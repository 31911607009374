import React from 'react'
import { number, string } from 'prop-types'
// ////////////////////////////////////////////////////////////////// COMPONENTS
import Icon from './icon'
// ////////////////////////////////////////////////////////////////// FLOW TYPES

// ////////////////////////////////////////////////////////////////// IMPORT END
// /////////////////////////////////////////////////////////////////////////////

type Props = {
  width?: number
  id?: string
  title?: string
  className?: string
  color?: string
}

const TagsIcon = ({
  width,
  title,
  id,
  className,
  color,
}: Props): React.ReactElement => {
  return (
    <Icon
      width={width ?? 14}
      sourceWidth={229}
      sourceHeight={195}
      title={title ?? 'Tags'}
      id={id ?? 'icon-tags-default'}
      className={className}
    >
      <g transform="translate(0,-20)">
        <g
          transform="matrix(1.0439491,0,0,1.0439491,-0.03234796,8.9927871)"
          fill="none"
          stroke={color ?? '#999'}
          strokeWidth="18"
          strokeLinejoin="round"
        >
          <path d="M 5.0312447,134.18783 5.031,45.927007 l 88.261067,1.96e-4 86.040673,86.040267 -88.260823,88.26082 z" />
          <circle cx="54.882149" cy="95.311905" r="20.272484" />
          <path d="m 126.85383,45.579956 86.04004,86.039794 -88.26082,88.26082" />
        </g>
      </g>
    </Icon>
  )
}

// /////////////////////////////////////////////////////////////////////////////
// ////////////////////////////////////////////////////////////// EXPORT DEFAULT
export default TagsIcon
