import { useTranslation } from 'next-i18next'
import getConfig from '@/config'
import Head from 'next/head'
import React from 'react'

import { PublicPostImageFragment } from '../../graphql/operations'
import { getImageURL } from '../../url'

const siteConfig = getConfig().publicRuntimeConfig

const BlogMeta = ({
  title,
  description,
  image,
  postIsCovid,
}: {
  title: string
  description: string
  image?: PublicPostImageFragment
  postIsCovid?: boolean
}) => {
  const { t } = useTranslation('blog')

  const DEFAULT_OG_IMAGE = 'static/images/medshr-og.png'
  const imageUrl =
    image && image.file
      ? getImageURL('medium', 'cms', image.path)
      : DEFAULT_OG_IMAGE

  if (description === null) {
    description = postIsCovid
      ? t('blog.covid_listing_page_sub_heading')
      : t('blog.listing_page_sub_heading')
  }

  return (
    <Head>
      <title>{title}</title>
      <meta property="og:title" content={title} />
      {description && <meta property="og:description" content={description} />}
      <meta property="og:image" content={imageUrl} />

      <link
        rel="alternate"
        type="application/rss+xml"
        title="MedShr &raquo; Blog Feed"
        href={`${siteConfig.mainSiteUrl}/medshr-open/feed.rss`}
      />
    </Head>
  )
}

export default BlogMeta
