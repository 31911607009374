import React, { useEffect, useRef } from 'react'
import { Page as BlockPage, ImageBlock } from '@cms/blocks'

import { getImageURL, qualifyUrl } from '../../url'

const CaseFeed = ({ value }) => {
  const group_id = value && value.group_id ? value.group_id : null
  const per_page = value && value.per_page ? value.per_page : 6
  const iframeRef = useRef(null)
  useEffect(() => {
    if (!iframeRef.current) {
      return
    }

    function updateStyle(event) {
      var origin = event.origin || event.originalEvent.origin
      const url = qualifyUrl('/')
      const overflow = 'scroll'
      const width = 0
      var m = url.match(/^(https?:\/\/[^\/]+)\//)
      if (m && origin.substring(0, m[1].length) === m[1]) {
        if (event.data && event.data.height) {
          var style = (
            'width:{0};height:{1};overflow:' +
            overflow +
            ';border:0'
          )
            .replace('{0}', width || '100%')
            .replace('{1}', event.data.height + 'px')
          iframeRef.current.setAttribute('style', style)
        }
      }
    }
    window.addEventListener('message', updateStyle, false)

    return () => window.removeEventListener('message', updateStyle)
  }, [iframeRef.current])

  return group_id ? (
    <iframe
      ref={iframeRef}
      src={qualifyUrl(
        `/api/rss/groups/${group_id}/cases.html?api_key=0f615fc16615c7ab53ca9705b2a0690b8cb5d109&per_page=${per_page}`
      )}
      style={{
        display: 'block',
        margin: '0 auto',
        width: '100%',
        height: '100%',
        border: 0,
      }}
      className="cms-block cms-iframe-block cms-iframe-block--case-feed"
    ></iframe>
  ) : null
}

const urlProvider = {
  document(value) {
    return ''
  },
  image(value, floated) {
    const url = getImageURL(floated ? 'medium' : 'original', 'cms', value.path)
    if (floated) {
      return { url, width: 300 }
    }
    return { url }
  },
}

ImageBlock.defaultClassNameMap = {
  centered: 'ui image centered',
  floatLeft: 'ui image floated left',
  floatRight: 'ui image floated right',
  fluid: 'ui centered fluid',
  spaced: 'spaced',
}

const blockTypes = {
  'case-feed': {
    component: CaseFeed,
  },
}

const PageContent = ({ blocks }) => (
  <BlockPage
    blocks={blocks}
    urlProvider={urlProvider}
    blockTypes={blockTypes}
  />
)

export default PageContent
